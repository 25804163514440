<template>
  <div
    v-if="filteredBenefit"
    class="bg-static-info-mid text-static-default-hi rounded-md body-2 mx-12 mt-8 flex items-center space-x-4 px-12 py-8"
  >
    <IconRocket />
    <FormattedMessage :definition="translations.title" tag="p">
      <template #flashOffer>
        <span class="body-2-bold">{{
          i18n(translations.flashOfferLabel)
        }}</span>
      </template>
      <template #previousBenefit>
        <span class="text-static-default-low body-2-striked">
          {{ filteredBenefit.previousBenefit }}
        </span>
      </template>
      <template #newBenefit>
        <span class="body-2">{{ filteredBenefit.newBenefit }} </span>
      </template>
    </FormattedMessage>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Benefit } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconRocket } from '@ds/icons/IconRocket'

import translations from './BouyguesFlashOffer.translations'

const i18n = useI18n()

const props = defineProps<{
  benefits: Benefit[]
}>()

const filteredBenefit = computed(() => {
  if (props.benefits.includes('FLASH_OFFER_130_GO_INSTEAD_OF_90_GO')) {
    return {
      previousBenefit: i18n(translations.flashOffer130GoPreviousBenefit),
      newBenefit: i18n(translations.flashOffer130GoNewBenefit),
    }
  }
  if (
    props.benefits.includes('FLASH_OFFER_180_EURO_SUBSIDY_INSTEAD_OF_150_EURO')
  ) {
    return {
      previousBenefit: i18n(translations.flashOffer180EuroPreviousBenefit),
      newBenefit: i18n(translations.flashOffer180EuroNewBenefit),
    }
  }

  return null
})
</script>
