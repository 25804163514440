export default {
  title: {
    id: 'bouygues_flash_offer_title',
    defaultMessage:
      '{flashOffer, html} {previousBenefit, html} {newBenefit, html}',
  },
  flashOfferLabel: {
    id: 'bouygues_flash_offer_label',
    defaultMessage: 'Flash offer :',
  },
  flashOffer130GoPreviousBenefit: {
    id: 'bouygues_flash_offer_130_go_previous_benefit',
    defaultMessage: '90Go',
  },
  flashOffer130GoNewBenefit: {
    id: 'bouygues_flash_offer_130_go_new_benefit',
    defaultMessage: '130Go',
  },
  flashOffer180EuroPreviousBenefit: {
    id: 'bouygues_flash_offer_180_euro_previous_benefit',
    defaultMessage: '150€',
  },
  flashOffer180EuroNewBenefit: {
    id: 'bouygues_flash_offer_180_go_new_benefit',
    defaultMessage: '180€ de réduction',
  },
}
